<template>
    <div :class="containerClass" @click="onDocumentClick">
        <AppTopBar :topbarMenuActive="topbarMenuActive" :activeTopbarItem="activeTopbarItem" :inlineUser="inlineUser"
            @right-menubutton-click="onRightMenuButtonClick" @menubutton-click="onMenuButtonClick"
            @topbar-menubutton-click="onTopbarMenuButtonClick" @topbar-item-click="onTopbarItemClick"></AppTopBar>

        <AppRightMenu :rightPanelMenuActive="rightPanelMenuActive" @rightmenu-click="onRightMenuClick"></AppRightMenu>
        <Toast />
        <transition name="layout-menu-container">
            <div class="layout-menu-container" @click="onMenuClick" v-show="isMenuVisible()">
                <div class="menu-scroll-content">
                    <div class="layout-profile" v-if="inlineUser">
                        <button class="p-link layout-profile-button" @click="onInlineUserClick">
                            <img src="layout/images/avatar.png" alt="roma-layout" />
                            <span class="layout-profile-userinfo">
                                <span class="layout-profile-name">Arlene Welch</span>
                                <span class="layout-profile-role">Design Ops</span>
                            </span>
                        </button>
                    </div>
                    <AppMenu :model="menu" :layoutMode="layoutMode" :active="menuActive"
                        :mobileMenuActive="staticMenuMobileActive" @menuitem-click="onMenuItemClick"
                        @root-menuitem-click="onRootMenuItemClick"></AppMenu>
                </div>
            </div>
        </transition>

        <div class="layout-main">
            <div class="layout-content">
                <router-view />
            </div>

            <!--
				Menu de configuração de tema, desativado na primeira versão.
				<AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" :lightMenu="lightMenu"
				@menu-color-change="onMenuColorChange" :inlineUser="inlineUser"
				@profile-mode-change="onProfileModeChange" :isRTL="isRTL" @orientation-change="onChangeOrientation"
				:topbarColor="topbarColor" :topbarColors="topbarColors" @topbar-color-change="onTopbarColorChange"
				:theme="theme" :themes="themeColors" @theme-change="onThemeChange">
			</AppConfig>-->

            <AppFooter />
        </div>

        <div class="layout-content-mask"></div>
    </div>
</template>
<script>
import AppTopBar from './AppTopbar.vue';
import AppFooter from './AppFooter.vue';
import AppRightMenu from './AppRightMenu.vue';
import AppMenu from './AppMenu.vue';
import EventBus from './event-bus';
import * as storage from './common/storage';
//import AppConfig from "./AppConfig"; Menu de configuração de tema, desativado na primeira versão.

export default {
    data() {
        return {
            profile: {},
            layoutMode: 'slim',
            lightMenu: true,
            overlayMenuActive: false,
            staticMenuMobileActive: false,
            staticMenuDesktopInactive: false,
            isRTL: false,
            inlineUser: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            rightPanelMenuActive: null,
            inlineUserMenuActive: false,
            menuActive: false,
            topbarColor: 'layout-topbar-dark',
            topbarColors: [
                { name: 'Light', topbarColor: 'layout-topbar-light', logo: 'logo-white', color: '#ffffff' },
                { name: 'Dark', topbarColor: 'layout-topbar-dark', logo: 'logo-white', color: '#252529' },
                { name: 'Blue', topbarColor: 'layout-topbar-blue', logo: 'logo-white', color: '#0772B3' },
                { name: 'Green', topbarColor: 'layout-topbar-green', logo: 'logo-white', color: '#0F8C50' },
                { name: 'Orange', topbarColor: 'layout-topbar-orange', logo: 'logo-white', color: '#C76D09' },
                { name: 'Magenta', topbarColor: 'layout-topbar-magenta', logo: 'logo-white', color: '#972BB1' },
                { name: 'Blue Grey', topbarColor: 'layout-topbar-bluegrey', logo: 'logo-white', color: '#406E7E' },
                { name: 'Deep Purple', topbarColor: 'layout-topbar-deeppurple', logo: 'logo-white', color: '#543CD9' },
                { name: 'Brown', topbarColor: 'layout-topbar-brown', logo: 'logo-white', color: '#794F36' },
                { name: 'Lime', topbarColor: 'layout-topbar-lime', logo: 'logo-white', color: '#849201' },
                { name: 'Rose', topbarColor: 'layout-topbar-rose', logo: 'logo-white', color: '#8F3939' },
                { name: 'Cyan', topbarColor: 'layout-topbar-cyan', logo: 'logo-white', color: '#0C8990' },
                { name: 'Teal', topbarColor: 'layout-topbar-teal', logo: 'logo-white', color: '#337E59' },
                { name: 'Deep Orange', topbarColor: 'layout-topbar-deeporange', logo: 'logo-white', color: '#D74A1D' },
                { name: 'Indigo', topbarColor: 'layout-topbar-indigo', logo: 'logo-white', color: '#3D53C9' },
                { name: 'Pink', topbarColor: 'layout-topbar-pink', logo: 'logo-white', color: '#BF275B' },
                { name: 'Purple', topbarColor: 'layout-topbar-purple', logo: 'logo-white', color: '#7F32DA' }
            ],
            theme: 'blue',
            themeColors: [
                { name: 'Blue', file: 'blue', color: '#0f97c7' },
                { name: 'Blue Grey', file: 'bluegrey', color: '#578697' },
                { name: 'Brown', file: 'brown', color: '#97664A' },
                { name: 'Cyan', file: 'cyan', color: '#1BA7AF' },
                { name: 'Deep Orange', file: 'deeporange', color: '#F96F43' },
                { name: 'Deep Purple', file: 'deeppurple', color: '#6952EC' },
                { name: 'Green', file: 'green', color: '#10B163' },
                { name: 'Teal', file: 'teal', color: '#4EA279' },
                { name: 'Indigo', file: 'indigo', color: '#435AD8' },
                { name: 'Lime', file: 'lime', color: '#A5B600' },
                { name: 'Magenta', file: 'magenta', color: '#B944D6' },
                { name: 'Orange', file: 'orange', color: '#E2841A' },
                { name: 'Pink', file: 'pink', color: '#E93A76' },
                { name: 'Purple', file: 'purple', color: '#9643F9' },
                { name: 'Rose', file: 'rose', color: '#AB5353' }
            ],
            menu: [
                {
                    label: 'Configurações',
                    permission: 'configuracoes:view',
                    icon: 'pi pi-fw pi-cog',
                    items: [
                        {
                            label: 'Base',
                            icon: '',
                            permission: 'configuracoes_base:view',
                            items: [
                                { label: 'Empresas', icon: 'pi pi-fw pi-building', to: '/companies', permission: 'configuracoes_empresa:view' },
                                { label: 'Usuários', icon: 'pi pi-fw pi-user', to: '/users', permission: 'configuracoes_usuario:view' },
                                { label: 'Perfil', icon: 'pi pi-fw pi-tags', to: '/profiles', permission: 'configuracoes_perfil:view' },
                                { label: 'Importações', icon: 'pi pi-fw pi-upload', to: '/importacao', permission: 'importacao_soc:view' },
                                { label: 'IA', icon: 'pi pi-fw pi-upload', to: '/open-ia', permission: 'configuracoes_empresa:view' },
                                {
                                    label: 'Importação Cadastro Estrutura',
                                    icon: 'pi pi-fw pi-upload',
                                    to: '/importacao-cadastro-estrutura',
                                    permission: 'importacao_cadastro_estrutura:view'
                                },
                                {
                                    label: 'Integrações',
                                    icon: 'pi pi-fw pi-upload',
                                    permission: 'integracoes:view',
                                    items: [
                                        { label: 'Apollus', icon: 'pi pi-fw pi-upload', to: '/integracao-apollus', permission: 'integracao_apollus:view' },
                                        { label: 'Gupy', icon: 'pi pi-fw pi-upload', to: '/integracao-gupy', permission: 'integracao_gupy:view' },
                                        { label: 'Senior', icon: 'pi pi-fw pi-upload', to: '/integracao-senior',permission: 'integracao_senior:view'},
                                    ]
                                },
                                {
                                    label: 'Relatórios',
                                    icon: 'pi pi-fw pi-file-o',
                                    permission: 'relatorios:view',
                                    items: [
                                        { label: 'Relatórios Agendados', icon: 'pi pi-fw pi-envelope', to: '/relatorios-agendados', permission: 'relatorios_agendados:view' }
                                    ]
                                },
                                { label: 'Boletins', icon: 'pi pi-fw pi-sync', to: '/boletins', permission: 'estrutura_boletins:view' },
                                { label: 'Feriados', icon: 'pi pi-fw pi-calendar', to: '/feriados', permission: 'configuracoes_feriados:view' },
                                { label: 'E-mail', icon: 'pi pi-fw pi-envelope', to: '/mail', permission: 'configuracoes_email:view' },
                            ]
                        },
                        //Ferramenta Será liberado na proxima versão [Deve ficar oculto].
                        {
                            label: 'Saúde',
                            icon: '',
                            permission: 'configuracoes_base:view',
                            items: [{ label: 'Gerais', icon: 'pi pi-fw pi-cog', to: '/configuracoes-gerais', permission: 'configuracoes:view' }]
                        },
                        {
                            label: 'Acesso ao SOC',
                            icon: 'pi pi-sign-out',
                            permission: 'configuracoes_usuario:acessoSoc',
                            to: '/acesso-soc'
                        }
                    ]
                },
                {
                    label: 'Estrutura',
                    permission: 'estrutura:view',
                    icon: 'pi pi-fw pi-building',
                    items: [
                        { label: 'Grupo', icon: 'pi pi-fw pi-building', to: '/grupos', permission: 'estrutura_grupos:view' },
                        { label: 'Cliente', to: '/customers', icon: 'pi pi-fw pi-building', permission: 'estrutura_cliente:view' },
                        { label: 'Unidade', icon: 'pi pi-fw pi-building', to: '/branch', permission: 'estrutura_unidade:view' },
                        { label: 'Setor', icon: 'pi pi-fw pi-table', to: '/department', permission: 'estrutura_setor:view' },
                        { label: 'Cargo', icon: 'pi pi-fw pi-briefcase', to: '/position', permission: 'estrutura_cargo:view' },
                        { label: 'Funcionário', to: '/employee', icon: 'pi pi-fw pi-user', permission: 'estrutura_funcionario:view' },
                        { label: 'Hierarquia', icon: 'pi pi-fw pi-sitemap', to: '/hierarchy', permission: 'estrutura_hierarquia:view' }
                    ]
                },
                {
                    label: 'Gestão Médica',
                    permission: 'gestamedica:view',
                    icon: 'pi pi-fw pi-heart',
                    items: [
                        {
                            label: 'Agendamentos',
                            icon: 'pi pi-fw pi-calendar',
                            to: '/gestao-medica-v3/agendamentos',
                            permission: 'gestamedica_agendamentos:view'
                        },
                        {
                            label: 'Painel de Agendamento',
                            icon: 'pi pi-fw pi-box',
                            to: '/painel-agendamento',
                            permission: 'gestamedica_painel_agendamento:view'
                        },
                        {
                            label: 'Depósito Antecipado',
                            icon: 'pi pi-fw pi-dollar',
                            to: '/deposito-antecipado',
                            permission: 'gestamedica_painel_deposito_antecipado:view'
                        },
                        {
                            label: 'Reservas de horários',
                            icon: 'pi pi-fw pi-box',
                            to: '/reserva-horario',
                            permission: 'gestamedica_painel_agendamento:view'
                        },
                        {
                            label: 'Painel Atendimento',
                            icon: 'pi pi-fw pi-box',
                            to: '/painel-atendimento',
                            permission: 'gestamedica_painel_atendimento:view'
                        },
                        // {
                        //     label: 'Painel Atendimento ²',
                        //     icon: 'pi pi-fw pi-video',
                        //     to: '/telemedicina',
                        //     permission: 'gestamedica_telemedicina:view'
                        // },
                        {
                            label: 'Painel de atendimento',
                            permission: 'gestamedica_painel_atendimento:view',
                            icon: '',
                            items: [
                                {
                                    label: 'Painel atendimento',
                                    icon: 'pi pi-fw pi-box',
                                    to: '/tela-chamada-profissional',
                                    permission: 'gestamedica_painel_atendimento_listagem:view'
                                },
                                {
                                    label: 'Tela de Chamada',
                                    icon: 'pi pi-fw pi-desktop',
                                    to: '/tela-chamada',
                                    permission: 'gestamedica_painel_atendimento_tela_chamada:view'
                                },
                                {
                                    label: 'Check-In',
                                    icon: 'pi pi-fw pi-check-circle',
                                    to: '/check-in/home',
                                    permission: 'gestamedica_painel_atendimento_check_in:view'
                                }
                            ]
                        },
                        {
                            label: 'Prestador',
                            permission: 'gestamedica_painel_prestador:view',
                            icon: '',
                            items: [
                                {
                                    label: 'Não Conformidade',
                                    icon: 'pi pi-fw pi-building',
                                    to: '/nao-conformidade',
                                    permission: 'gestamedica_painel_prestador_nao_conformidade:view'
                                },
                                {
                                    label: 'Liberar Prestador',
                                    icon: 'pi pi-fw pi-lock',
                                    to: '/liberar-prestador',
                                    permission: 'gestamedica_painel_prestador_liberar_prestador:view'
                                },
                                {
                                    label: 'Prestador',
                                    icon: 'pi pi-fw pi-building',
                                    to: '/supplier',
                                    permission: 'gestamedica_painel_prestador_prestador:view'
                                },
                                {
                                    label: 'Atualizar prestador',
                                    icon: 'pi pi-fw pi-pencil',
                                    to: '/atualizar-prestador',
                                    permission: 'gestamedica_painel_prestador_prestador:view'
                                }
                            ]
                        },

                        {
                            label: 'Cadastro',
                            permission: 'gestamedica_painel_cadastro:view',
                            icon: '',
                            items: [
                                { label: 'Médicos', icon: 'pi pi-fw pi-user', to: '/medic', permission: 'gestamedica_painel_cadastro_medicos:view' },
                                {
                                    label: 'Funcionários internos',
                                    icon: 'pi pi-fw pi-user',
                                    to: '/profissionais',
                                    permission: 'gestamedica_painel_cadastro:view'
                                },
                                {
                                    label: 'Questionário Telemedicina',
                                    icon: 'pi pi-fw pi-question',
                                    to: '/questionario-telemedicina',
                                    permission: 'gestamedica_painel_cadastro_questionario_telemedicina:view'
                                }
                            ]
                        },
                        {
                            label: 'Formulários',
                            icon: '',
                            to: '/gestao-medica/formularios',
                            permission: 'gestaomedica_painel_formularios:view'
                        }
                    ]
                },
                {
                    label: 'Gestão Sesmt',
                    permission: 'gestaosesmt:view',
                    icon: 'pi pi-fw pi-shield',
                    items: [
                        {
                            label: 'Gestão de Riscos',
                            icon: 'pi pi-fw pi-check-square',
                            to: '/gestao-risco/clientes',
                            permission: 'gestaosesmt_gro:view'
                        },
                        {
                            label: 'Inventário de Risco',
                            to: '/security/inventario-risco2',
                            icon: 'pi pi-fw pi-check-square',
                            permission: 'gestaosesmt_inventario_risco:view'
                        },
                        {
                            label: 'Plano de Ação',
                            icon: 'pi pi-fw pi-home',
                            to: '/plano-acao/listar',
                            permission: 'gestaosesmt_cadastros_plano_acao:listar'
                        },
                        {
                            label: 'Liberacao de Hierarquia',
                            to: '/security/libera-hierarquia',
                            icon: 'pi pi-fw pi-check-square',
                            permission: 'gestaosesmt_inventario_risco:view'
                        },
                        { label: 'Rotas', to: '/security/routes', icon: 'pi pi-fw pi-map-marker', permission: 'gestaosesmt_rotas:view' },
                        {
                            label: 'Cadastros',
                            permission: 'gestaosesmt_cadastros:view',
                            icon: '',
                            items: [
                                {
                                    label: 'Atividades específicas',
                                    icon: 'pi pi-fw pi-plus',
                                    to: '/atividades-especificas',
                                    permission: 'gestaosesmt_cadastros_atividades_especificas:view'
                                },
                                {
                                    label: 'Procedimentos',
                                    icon: 'pi pi-fw pi-plus',
                                    to: '/procedure',
                                    permission: 'gestamedica_painel_cadastro_exames:view'
                                },
                                {
                                    label: 'Fonte Geradora',
                                    to: '/fonte-geradora',
                                    icon: 'pi pi-fw pi-plus',
                                    permission: 'gestaosesmt_cadastros_fonte_geradora:view'
                                },
                                {
                                    label: 'Competências',
                                    to: '/competencias',
                                    icon: 'pi pi-fw pi-plus',
                                    permission: 'gestaosesmt_cadastros_competencias:view'
                                },
                                {
                                    label: 'Descrição do Risco',
                                    to: '/descricao-risco',
                                    icon: 'pi pi-fw pi-plus',
                                    permission: 'gestaosesmt_cadastros_descricao_risco:view'
                                },
                                { label: 'EPC', to: '/EPC', icon: 'pi pi-fw pi-plus', permission: 'gestaosesmt_cadastros_epc:view' },
                                { label: 'EPI', to: '/EPI', icon: 'pi pi-fw pi-plus', permission: 'gestaosesmt_cadastros_epi:view' },
                                {
                                    label: 'Matriz Avaliação de risco',
                                    to: '/matriz-config',
                                    icon: 'pi pi-fw pi-plus',
                                    permission: 'gestaosesmt_cadastros_matriz_config:view'
                                },
                                {
                                    label: 'Profissionais SESMT',
                                    to: '/profissionais-sesmt',
                                    icon: 'pi pi-fw pi-plus',
                                    permission: 'gestaosesmt_cadastros_fonte_geradora:view'
                                },
                                {
                                    label: 'Medida de controle',
                                    to: '/medida-adm-engenharia',
                                    icon: 'pi pi-fw pi-plus',
                                    permission: 'gestaosesmt_cadastros_medida_controle:view'
                                },
                                {
                                    label: 'Perigo/Fator de Risco',
                                    icon: 'pi pi-fw pi-plus',
                                    to: '/riscos',
                                    permission: 'gestaosesmt_cadastros_riscos:view'
                                },
                                {
                                    label: 'Produtos e Serviços',
                                    to: '/produtos-servicos',
                                    icon: 'pi pi-fw pi-plus',
                                    permission: 'gestaosesmt_cadastros_produto_servico:view'
                                },
                                {
                                    label: 'Equip. de Proteção',
                                    permission: 'gestamedica_painel_questionario:view',
                                    items: [
                                        { label: 'EPC', to: '/EPC', icon: 'pi pi-fw pi-plus', permission: 'gestaosesmt_cadastros_epc:view' },
                                        { label: 'EPI', to: '/EPI', icon: 'pi pi-fw pi-plus', permission: 'gestaosesmt_cadastros_epi:view' }
                                    ]
                                },
                                {
                                    label: 'Checklists',
                                    permission: 'gestaosesmt_cadastros_checklists:view',
                                    items: [
                                        {
                                            label: 'Perguntas',
                                            icon: 'pi pi-fw pi-plus',
                                            to: '/perguntas-sesmt',
                                            permission: 'gestaosesmt_cadastros_questionario_sesmt_perguntas:view'
                                        },
                                        {
                                            label: 'Questionários',
                                            icon: 'pi pi-fw pi-plus',
                                            to: '/questionarios-sesmt',
                                            permission: 'gestaosesmt_cadastros_questionario_sesmt:view'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    label: 'eSocial',
                    permission: 'esocial:view',
                    icon: 'pi pi-fw pi-cloud',
                    items: [
                        {
                            label: 'Painel eSocial',
                            icon: 'pi pi-fw pi-eye',
                            to: '/esocial/dashboard',
                            permission: 'esocial:view'
                        },
                        {
                            label: 'Eventos',
                            icon: 'pi pi-fw pi-bars',
                            to: '/esocial/list',
                            permission: 'esocial_eventos:view'
                        }
                    ]
                },
                {
                    label: 'Ferramentas',
                    permission: 'ferramentas:view',
                    icon: 'pi pi-fw pi-calendar-plus',
                    items: [
                        {
                            label: 'Agenda Veículos',
                            to: '/vehiclesScheduling',
                            icon: 'pi pi-fw pi-calendar',
                            permission: 'ferramentas_cadastro_veiculos:view'
                        },
                        {
                            label: 'Agenda Equipamentos',
                            to: '/equipment/list',
                            icon: 'pi pi-fw pi-calendar',
                            permission: 'ferramentas_cadastro_equipamento:view'
                        },
                        {
                            label: 'Cadastro',
                            permission: 'ferramentas_cadastro:view',
                            items: [
                                { label: 'Veículos', to: '/vehicles', icon: 'pi pi-fw pi-car', permission: 'ferramentas_veiculos:view' },
                                { label: 'Equipamentos', to: '/equipment', icon: 'pi pi-fw pi-camera', permission: 'ferramentas_equipamento:view' },
                                {
                                    label: 'Agendas Médica',
                                    icon: 'pi pi-fw pi-calendar',
                                    to: '/agenda',
                                    permission: 'ferramentas_cadastro_agenda_medica:view'
                                },
                                { label: 'Ambientes', icon: 'pi pi-fw pi-table', to: '/ambiente', permission: 'ferramentas_cadastro_ambientes:view' }
                            ]
                        }
                    ]
                },
                {
                    label: 'Relatórios e indicadores',
                    permission: 'indicadores:view',
                    icon: 'pi pi-fw pi-chart-pie',
                    items: [
                        {
                            label: 'Solicitação por cliente',
                            to: '/relatorios-e-indicadores/solicitacao-por-cliente',
                            icon: 'pi pi-fw pi-user',
                            permission: 'indicadores_solicitacoes_por_cliente:view'
                        },
                        {
                            label: 'Produtividade individual',
                            to: '/relatorios-e-indicadores/produtividade',
                            icon: 'pi pi-fw pi-chart-line',
                            permission: 'indicadores_produtividade_individual_graficos:view'
                        },
                        {
                            label: 'Produtividade coletiva',
                            to: '/relatorios-e-indicadores/produtividade-coletiva',
                            icon: 'pi pi-fw pi-chart-bar',
                            permission: 'indicadores_produtividade_coletiva:view'
                        },
                        {
                            label: 'Envio de e-mail',
                            to: '/relatorios-e-indicadores/envio-de-email',
                            icon: 'pi pi-fw pi-envelope',
                            permission: 'indicadores_envio_de_email:view'
                        },
                        {
                            label: 'Prestadores',
                            to: '/relatorios-e-indicadores/prestadores',
                            icon: 'pi pi-fw pi-users',
                            permission: 'indicadores_prestadores:view'
                        }
                    ]
                },
                {
                    label: 'Dashboard',
                    permission: 'indicadores_cliente:view',
                    icon: 'pi pi-fw pi-chart-pie',
                    items: [
                        {
                            label: 'Estrutura',
                            to: '/dashboard/estrutura',
                            icon: 'pi pi-fw pi-user',
                            permission: 'indicadores_cliente_estrutura:view'
                        },
                        {
                            label: 'Agendamentos',
                            to: '/dashboard/agendamentos',
                            icon: 'pi pi-fw pi-chart-line',
                            permission: 'indicadores_cliente_agendamentos:view'
                        },
                        {
                            label: 'Falta Consulta',
                            to: '/dashboard/falta-consulta',
                            icon: 'pi pi-fw pi-chart-bar',
                            permission: 'indicadores_cliente_falta_consulta:view'
                        }
                    ]
                },
                {
                    label: 'Atualizações',
                    permission: 'estrutura:view',
                    icon: 'pi pi-sync',
                    to: '/boletins/view'
                }
            ]
        };
    },
    watch: {
        $route() {
            this.menuActive = false;
        }
    },
    mounted() {
        this.profile = storage.getProfile();
        // this.socketHandler();
    },
    methods: {
        // socketHandler() {
        //     this.$socket.on('connect', () => {
        //         this.sendUserData();
        //     });
        //     this.$socket.on('reconnect', () => {
        //         this.sendUserData();
        //     });
        //     this.$socket.on('client_connected', () => {
        //         this.sendUserData();
        //     });
        // },
        // sendUserData() {
        //     this.$socket.emit('send_user_data', { userId: this.profile.id });
        // },
        onDocumentClick() {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }
            if (!this.rightMenuClick) {
                this.rightPanelMenuActive = false;
            }

            if (!this.userMenuClick && this.isSlim() && !this.isMobile()) {
                this.inlineUserMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isHorizontal() || this.isSlim()) {
                    this.menuActive = false;
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.hideOverlayMenu();
                }

                EventBus.emit('reset-active-index');
                this.unblockBodyScroll();
            }

            if (this.userMenuClick) {
                this.menuActive = false;
                EventBus.emit('reset-active-index');
            }

            this.topbarItemClick = false;
            this.menuClick = false;
            this.rightMenuClick = false;
            this.userMenuClick = false;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;
            this.topbarMenuActive = false;
            this.rightPanelMenuActive = false;

            if (this.layoutMode === 'overlay') {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
                if (this.staticMenuMobileActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }

            event.preventDefault();
        },
        onTopbarMenuButtonClick(event) {
            this.topbarItemClick = true;
            this.topbarMenuActive = !this.topbarMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onTopbarItemClick(event) {
            this.topbarItemClick = true;

            if (this.activeTopbarItem === event.item) this.activeTopbarItem = null;
            else this.activeTopbarItem = event.item;

            event.originalEvent.preventDefault();
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onInlineUserClick() {
            this.userMenuClick = true;
            this.inlineUserMenuActive = !this.inlineUserMenuActive;
        },
        blockBodyScroll() {
            this.addClass(document.body, 'blocked-scroll');
        },
        unblockBodyScroll() {
            this.removeClass(document.body, 'blocked-scroll');
        },
        onRightMenuButtonClick(event) {
            this.rightMenuClick = true;
            this.rightPanelMenuActive = !this.rightPanelMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        onRightMenuClick() {
            this.rightMenuClick = true;
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                EventBus.emit('reset-active-index');
                this.hideOverlayMenu();
            }
            if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
                this.menuActive = false;
            }
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
        },
        isTablet() {
            let width = window.innerWidth;
            return width <= 1024 && width > 640;
        },
        isDesktop() {
            return window.innerWidth > 896;
        },
        isMobile() {
            return window.innerWidth <= 1025;
        },
        isHorizontal() {
            return this.layoutMode === 'horizontal';
        },
        isSlim() {
            return this.layoutMode === 'slim';
        },
        isMenuVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static') return !this.staticMenuDesktopInactive;
                else if (this.layoutMode === 'overlay') return this.overlayMenuActive;
                else return true;
            } else {
                return true;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
            this.staticMenuDesktopInactive = false;
            this.overlayMenuActive = false;

            if (this.isSlim() || this.isHorizontal()) {
                this.inlineUser = false;
                this.inlineUserMenuActive = false;
            }
        },
        onMenuColorChange(menuColor) {
            this.lightMenu = menuColor;
        },
        onProfileModeChange(profileMode) {
            this.inlineUser = profileMode;
        },
        onChangeOrientation(orientation) {
            this.isRTL = orientation;
        },
        onTopbarColorChange(topbarColor, logo) {
            this.topbarColor = topbarColor;
            const topbarLogoLink = document.getElementById('topbar-logo');
            topbarLogoLink.src = 'layout/images/' + logo + '.png';
        },
        onThemeChange(theme) {
            this.theme = theme;
            this.changeStyleSheetUrl('layout-css', theme, 'layout');
            this.changeStyleSheetUrl('theme-css', theme, 'theme');
        },
        changeStyleSheetUrl(id, value, prefix) {
            let element = document.getElementById(id);
            let urlTokens = element.getAttribute('href').split('/');
            urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
            let newURL = urlTokens.join('/');

            this.replaceLink(element, newURL);
        },
        replaceLink(linkElement, href) {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        },
        addClass(element, className) {
            if (element.classList) element.classList.add(className);
            else element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList) element.classList.remove(className);
            else element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    },
    computed: {
        containerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-horizontal': this.layoutMode === 'horizontal',
                    'layout-overlay': this.layoutMode === 'overlay',
                    'layout-static': this.layoutMode === 'static',
                    'layout-slim': this.layoutMode === 'slim',
                    'layout-menu-light': this.lightMenu === true,
                    'layout-menu-dark': this.lightMenu === false,
                    'layout-overlay-active': this.overlayMenuActive,
                    'layout-mobile-active': this.staticMenuMobileActive,
                    'layout-static-inactive': this.staticMenuDesktopInactive,
                    'layout-rtl': this.isRTL,
                    'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                    'p-ripple-disabled': this.$primevue.config.ripple === false
                },
                this.topbarColor
            ];
        }
    },
    components: {
        AppTopBar: AppTopBar,
        AppFooter: AppFooter,
        AppRightMenu: AppRightMenu,
        AppMenu: AppMenu
        //'AppConfig': AppConfig, Menu de configuração de tema, desativado na primeira versão.
    }
};
</script>
<style scoped>
body {
    font-family: 'Inter UI', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #252529;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    min-height: 100%;
    background-color: #f4f4f4;
}

.layout-wrapper .layout-main .layout-content {
    flex: 1 1 0;
}

.layout-main {
    min-height: -webkit-calc(100% - 25px);
}

* {
    box-sizing: border-box;
}

div {
    display: block;
}
</style>

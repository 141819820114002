<template>
    <div class="layout-footer">
        <button class="info" @click="openDialog">
            <i class="pi pi-building"></i>
            {{ infoEmpresa }}
            <i v-if="infoCliente" class="pi pi-home ml-3"></i>
            {{ infoCliente }}
        </button>
        <button v-if="infoClienteCompleto !== infoCliente" class="info" v-tooltip.top="infoClienteCompleto">[Exibir mais]</button>
    </div>
    <Dialog v-model:visible="openDialogConfig" class="p-fluid" :style="{ width: '400px' }" :closable="false" :modal="true">
        <div class="grid">
            <div class="max-w-full col-12 field m-0">
                <label for="Empresa">Empresa prestadora do serviço:</label>
                <Dropdown
                    id="company"
                    v-model="selectedCompany"
                    :options="companies"
                    optionLabel="name"
                    dataKey="id"
                    placeholder="Selecione..."
                    @change="onChangeCompany"
                >
                </Dropdown>
            </div>
            <div class="max-w-full col-12 field m-0 mb-0">
                <label for="Cliente">Cliente beneficiado pelo serviço:</label>
                <MultiSelect
                    v-model="selectedCustomer"
                    :options="customers"
                    :filter="true"
                    optionLabel="name"
                    dataKey="id"
                    :showToggleAll="false"
                    placeholder="Selecione..."
                    @change="changeCustomer"
                />
            </div>
        </div>
        <template #footer>
            <Button label="Voltar" icon="pi pi-arrow-left" class="p-button-text" @click="openDialogConfig = false" />
        </template>
    </Dialog>
</template>

<script>
import EventBus from './event-bus';
import BaseService from './services/BaseService';
import * as store from './services/store';

export default {
    emits: [],
    props: {},
    data() {
        return {
            companies: [],
            selectedCompany: null,
            customers: [],
            selectedCustomer: [],
            openDialogConfig: false,
            infoEmpresa: null,
            infoCliente: null,
            infoClienteCompleto: null
        };
    },
    async mounted() {
        this.loadCompanies();
        this.loadCustomer();

        this.selectedCompany = store.getCurrentCompanyObject();
        this.selectedCustomer = store.getCurrentCustomersObjects();
        this.selectedFilterCustomer = store.getCurrentFilterCustomerObjects();

        EventBus.on('cliente-salvo', () => {
            this.loadCustomer();
        });
        EventBus.on('cliente-excluido', (record) => {
            this.loadCustomer();
            this.selectedCustomer = this.selectedCustomer.filter((item) => item.id != record.id);
            store.setCurrentCustomers(this.selectedCustomer);
            store.setCurrentFilterCustomers(this.selectedCustomer);
            this.infos();
        });
        EventBus.on('empresa-salva', () => {
            this.loadCompanies();
        });
        EventBus.on('empresa-excluida', () => {
            this.loadCompanies();
        });
        this.infos();

        document.addEventListener('sem-api-key', this.recuperarApiKey);
    },
    unmounted() {
        document.removeEventListener('sem-api-key', this.recuperarApiKey);
    },
    methods: {
        openDialog() {
            this.openDialogConfig = true;
            this.loadCompanies();
            this.loadCustomer();
        },
        async loadCompanies() {
            const { data } = await new BaseService('/companies/companies-by-user/combo').findAll({});
            this.companies = data;
        },
        async loadCustomer() {
            const { data } = await new BaseService('/customers/combo').findAll({});
            this.customers = data;
        },
        onChangeCompany() {
            store.setCurrentCompany(this.selectedCompany);
            store.setCurrentCustomers([]);
            store.setCurrentFilterCustomers([]);
            this.selectedCustomer = [];
            this.loadCustomer();
            this.infos();
        },
        async changeCustomer() {
            store.setCurrentCustomers(this.selectedCustomer);
            store.setCurrentFilterCustomers(this.selectedCustomer);
            await this.infos();
        },
        async infos() {
            this.infoEmpresa = 'Empresa: ' + this.selectedCompany.name;
            this.infoCliente = null;
            this.infoClienteCompleto = null;

            if (this.selectedCustomer?.length) {
                const selectedCustomerName = this.selectedCustomer?.map((c) => c.name);
                this.infoClienteCompleto = ' Cliente: ' + selectedCustomerName;
                this.infoCliente = ' Cliente: ' + selectedCustomerName[0];
            } else {
                await this.loadCustomer();
            }
        },
        recuperarApiKey() {
            this.selectedCompany = null;
            this.selectedCustomer = [];
            this.openDialogConfig = true;
            this.$toast.add({
                severity: 'error',
                summary: 'Empresa não informada na solicitação !',
                life: 4000,
                detail: 'Por favor selecione a empresa novamente.'
            });
        }
    }
};
</script>

<style scoped lang="scss">
.info {
    background: #cacaca;
    color: #444444;
    border: 0px;
    font-size: 10.5px;
    cursor: pointer;
}
</style>

<template>
    <div class="layout-topbar flex w-full col-12 topbar">
        <!-- Menu de configuração de tema, desativado na primeira versão.
		<button class="p-link layout-right-panel-button layout-topbar-icon" id="menu-button"
			@click="onRightMenuButtonClick">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		-->

        <button class="p-link layout-menu-button layout-topbar-icon" @click="onMenuButtonClick">
            <i class="pi pi-bars"></i>
        </button>

        <router-link to="/" class="p-link logo layout-topbar-logo">
            <img id="topbar-logo" src="layout/images/duon_branca.png" alt="duon-logo" />
        </router-link>

        <!-- Menu de configuração de tema, desativado na primeira versão.
		<span class="layout-topbar-search">
			<i class="pi pi-search"></i>
			<input type="text" placeholder="Pesquisar" />
		</span>
		-->
        <div class="flex ml-auto options">
            <AppNotificacaoButton />
            <ul class="topbar-menu">
                <li :class="['user-profile', { 'active-topmenuitem fadeInDown': activeTopbarItem === 'profile' }]">
                    <button class="flex flex-row align-items-center p-link" v-if="!this.inlineUser"
                        @click="$emit('topbar-item-click', { originalEvent: $event, item: 'profile' })">
                        <div class="user-photo-template">
                            <img :src="profile.urlFotoPerfil || 'layout/images/avatar.png'" class="user-photo"
                                height="100" preview />
                        </div>
                        <div class="layout-profile-userinfo mt-0 mb-1">
                            <span class="layout-profile-name">{{ profile.name }}</span>
                            <span v-if="profile?.profile?.name" class="layout-profile-role">{{ profile?.profile?.name
                                }}</span>
                        </div>
                    </button>
                    <transition name="layout-submenu-container">
                        <ul class="fadeInDown" v-show="activeTopbarItem === 'profile'">
                            <li role="menuitem">
                                <button class="p-link" @click="$router.push('/profile')">
                                    <i class="pi pi-fw pi-user"></i>
                                    <span>Perfil</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button class="p-link" @click="logout">
                                    <i class="pi pi-fw pi-sign-out"></i>
                                    <span>Sair</span>
                                </button>
                            </li>
                        </ul>
                    </transition>
                </li>

                <!-- Menu de configuração de tema, desativado na primeira versão. -->
            </ul>
        </div>
    </div>
</template>

<script>
import EventBus from './event-bus';
import { logout } from './services/auth';
import * as storage from './common/storage';

export default {
    emits: ['menubutton-click', 'topbar-menubutton-click', 'topbar-item-click', 'right-menubutton-click'],
    props: {
        topbarMenuActive: Boolean,
        activeTopbarItem: String,
        inlineUser: Boolean
    },
    data() {
        return {
            profile: {}
        };
    },
    async mounted() {
        this.profile = storage.getProfile();

        EventBus.on('updateProfile', (profile) => {
            this.profile = profile;
        });
    },
    methods: {
        logout() {
            logout(this.retornarAoLogin);
        },
        retornarAoLogin() {
            this.$router.replace('/login');
        },
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onTopbarMenuButtonClick(event) {
            this.$emit('topbar-menubutton-click', event);
        },
        onRightMenuButtonClick(event) {
            this.$emit('right-menubutton-click', event);
        }
    }
};
</script>

<style scoped lang="scss">
.user-photo-template {
    border: 1px solid #364DE7;
    border-style: solid;
    width: 50px;
    height: 50px;
    align-self: center;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.user-photo {
    width: initial;
    height: inherit;
    max-width: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 960px) {
    .logo {
        margin-top: auto;
        margin-bottom: auto;
    }

    .options {
        display: grid;
        margin-top: auto;
        margin-bottom: auto;
        // width: 250px;
    }
}
</style>
